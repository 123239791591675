@import '~assets/styles/colors.scss';
@import '~assets/styles/typography.scss';
@import '~assets/styles/variables.scss';


//import this default in index.js
// ::-webkit-input-placeholder {
//     // color: $textInputPlaceholder;
//     opacity: 1;
// }

// ::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
// }

// // ::-webkit-scrollbar-track {
// //     background-color:  $scrollbarBackground;
// // }

// ::-webkit-scrollbar-thumb {
//     background-color: $scrollbarColor;
//     border: 2px solid $scrollbarBackground;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-corner {
//     background-color: $scrollbarBackground;
// }

// * {
//     box-sizing: border-box;
// }

// html {
//     height: 100%;
//     width: 100%;
//     overflow: hidden;
// }

// body {
//     margin: 0;
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//       'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//       sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     text-align: center;
//     background-color: #0F182A;
//     box-sizing: border-box;
//   }
  

// a {
//     color: $textLink;

//     &:active {
//         color: $textLink;
//     }

//     &:hover {
//         text-decoration: underline;
//     }

//     &[disabled],
//     &[disabled]:hover {
//         opacity: .5;
//     }
// }

.header {
    @include font-base($fontSixeXXLarge, $fontWeightThick);
    line-height: 1.5rem;
} 

.subHeader {
    @include font-base($fontSixeXXLarge, $fontWeightThick);
    line-height: 1.5rem;
} 

.title {
    @include font-base($fontSixeXXXLarge, $fontWeightThick);
    line-height: 1.5rem;
} 

.para {
    @include font-base($fontSixeXLLarge, $fontWeightNormal);
    line-height: 1.4rem;
} 
