@import 'assets/styles/colors.scss';
@import 'assets/styles/typography.scss';

.about {
    padding-top: 10%;
    padding-bottom: 20px;

    b {
        color: $white;
    }
}

.summary {
    @include font-base($fontSixeXXLarge, $fontWeightLessBold);
    line-height: 1.5rem;
}