
nav {
    padding-top: 10%;
}

li {
    list-style: none;
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 700;
    text-align: left;

    a:hover {
        color: white !important;
        cursor: pointer;

        span {
            background-color: white;
            width: 70px;
        }
    }
}

.navLinks {
    color: #64748B; // text link
    text-decoration: none;
    text-transform: uppercase;
}

.active {
    color: white;
    
    span {
        background-color: white;
        width: 70px;
    }
}

.dash {
    background-color: #64748B;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 1px;
    top: 7px;
    margin-right: 15px;
}

@media (max-width: 768px) {
    nav {
        display: none;
    }
}