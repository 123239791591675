.sideBar {
    position: fixed;
    height: 100%;
    overflow: auto;
    width: 30%;
    margin-top: 4%;
}

@media (max-width: 768px) {
    .sideBar {
        position: relative;
        width: 100%;
    }
}