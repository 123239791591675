// Colors

$white: #fff;
$black: #000;
$lavender: #ab80ff;
$navyBlue: #0F182A;
$cement: #eaedf1;
$lightCement: #2D3851;
$lightGreen : #53D1BE;
$DarkGreen : #12303C;
$lightGrey: #91A0B6;

//Heading
$headerText: $cement;

//text
$textHighLight: $cement;
$textBase: $lightGrey;
$textLink: $lavender;
// $textError: $tomato;
$textLabel: $white;
$textWarning: $white;
$header: $cement;

//active links
$activeLink: $cement;

//Primary Button
$buttonPrimaryText: $lightGreen;
$buttonBackgroundColor: $DarkGreen;