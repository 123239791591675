@import '~assets/styles/defaults.scss';

.card {
    border: 0;
    background-color: transparent;
    padding: 25px 15px;
    margin: 10px 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
        background-color: #151F32;
        border-radius: 2px;

        .title {
            color: $buttonPrimaryText;
        }
    }
}

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.box-33 {
    width: calc(30% - 15px);
}

.box-66 {
    width: calc(70% - 15px);
}

.btn {
    font-size: $fontSixeLarge;
    border-radius: 12px;
    border: 0;
    min-width: 60px;
    color: $buttonPrimaryText;
    background-color: $buttonBackgroundColor;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 30px;
    vertical-align: middle;
    pointer-events: none;
}

.dates {
    @include font-base($fontSixeXLarge, $fontWeightNormal);
    text-transform: uppercase;
}

img {
    margin-top: 15px;
    height: 80px;
    width: 140px;
}

@media (max-width:768px) {
    .para {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    img {
        height: 70px;
        width: 100px;
    }
}